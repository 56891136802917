<style lang="less" scoped>
  @import "../../../assets/css/variables";
  .image-m-l {
    margin-left: 25px;
  }
  .ql-container .ql-editor {
    min-height: 20em;
    padding-bottom: 1em;
    max-height: 25em;
  }
  .order-image-wrapper {
    position: relative;
    height: 120px;
    width: 120px;
    border: 1px solid #bfcbd9;
    border-radius: 4px;
    font-size: 100px;
    line-height: 120px;
    text-align: center;
    color: #ccc;
    cursor: pointer;
    .image-content {
      position: relative;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .image-close {
      position: absolute;
      right: 0;
      top: 0;
      height: 20px;
      width: 20px;
      text-align: center;
      line-height: 20px;
      background-color: #1c2b36;
      color: #fff;
      font-size: @font-size-base;
    }
  }
</style>
<style>
  .ql-toolbar.ql-snow {
    border: 1px solid #bfcbd9;
    border-radius: 4px 4px 0 0;
  }
  .ql-container.ql-snow {
    border: 1px solid #bfcbd9;
    border-radius: 0 0 4px 4px;
  }
  .ql-image {
    display: none !important;
  }
</style>

<template :is="view" transition="fade" transition-mode="out-in">
  <div class="page-wrapper" id="pgInfo">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/service/infobase/list' }">
        运维服务
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/service/infoBase/addInfo' }">
        新增知识库
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-container">
      <el-form
        :model="infoBase"
        ref="infoForm"
        label-width="100px"
        style="width: 95%;"
        :rules="rules"
      >
        <el-form-item label="标题:" prop="title">
          <el-input v-model="infoBase.title" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="类别:" prop="type">
          <el-select
            v-model="infoBase.type"
            filterable
            clearable
            placeholder="请选择故障类别"
            style="width: 300px;"
          >
            <el-option
              v-for="item in orderCategoryList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片:" prop="image">
          <form
            id="headImgfileForm"
            class="none"
            method="post"
            enctype="multipart/form-data"
          >
            <input
              type="file"
              id="orderImageFile"
              @change="selectedImageFile"
              name="upfile"
              accept="image/*;capture=camera"
            />
          </form>
          <div class="image-content m-t-md clearfix" style="margin-top: -5px;">
            <div
              @click="triggerSelectFile"
              class="order-image-wrapper pull-left"
            >
              <span>+</span>
            </div>
            <div
              @click="delImage(index)"
              :class="[
                'order-image-wrapper',
                'pull-left',
                'm-b-md',
                { 'image-m-l': (index + 1) % 3 !== 0 },
              ]"
              v-for="(image, index) in imageList"
            >
              <div
                class="image-content "
                :style="{ 'background-image': 'url(' + image.fileImage + ')' }"
                v-loading.body="loading"
              ></div>
              <div class="image-close" style="background-color: transparent">
                <i class="fa fa-times-circle" style="color:#f00;"></i>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item type label="内容:" prop="content" style="height:380px;">
          <!-- <el-input type="textarea" :rows="10" :maxlength="200" v-model="infoBase.content"></el-input> -->
          <quill-editor
            v-model="infoBase.content"
            style="height:290px;z-index:99999;"
            ref="myQuillEditor"
          ></quill-editor>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('infoForm')">
            提交
          </el-button>
          <el-button @click="resetForm('infoForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import moment from "moment";
  import { API_ROOT } from "../../../config";
  import { getFileUrl } from "../../../utils/tools/tools";
  import { uploadImageFile } from "../../../services/common";
  import {
    getKnowledgeBase,
    createKnowledgeBase,
    updateKnowledgeBase,
  } from "../../../services/knowledgeBase";
  import { getAllfaultLevels } from "../../../services/orderCategory";
  import { Notification } from "element-ui";
  import { mapGetters, mapActions } from "vuex";
  import { PAGE_SIZE } from "../../../config";
  import uploadDefaultImg from "../../../assets/images/upload-defualt.png";
  import { quillEditor } from "vue-quill-editor";

  export default {
    components: {
      quillEditor,
    },
    data() {
      const valiTitle = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("标题不能为空"));
        } else {
          callback();
        }
      };
      const valiType = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("请选择一项"));
        } else {
          callback();
        }
      };
      return {
        infoBase: {
          title: "",
          image: "",
          content: "",
          type: "",
        },
        orderCategoryList: [],
        imageList: [], //上传的图片集合
        imgUrlList: [], //存放地址
        loading: false, //加载框
        rules: {
          title: [{ validator: valiTitle, trigger: "blur" }],
          type: [{ validator: valiType, trigger: "blur" }],
        },
      };
    },
    computed: {
      editor() {
        return this.$refs.myQuillEditor.quill;
      },
      ...mapGetters({ user: "userInfo" }),
    },
    methods: {
      /**
       * 获取所有故障类别
       */
      async getAllOrderCategory() {
        const responseData = await getAllfaultLevels("", {
          size: 999,
          page: 1,
        });
        if (responseData && responseData.errorCode === 0 && responseData.data) {
          this.orderCategoryList = responseData.data.content;
        }
      },

      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.imgUrlList = [];
            this.imageList.forEach((item) => {
              this.imgUrlList.push(API_ROOT + item.serverImage);
            });
            this.createinfoBase();
            this.$refs[formName].resetFields();
            this.infoBase.type = "";
            this.imageList = [];
          }
          window.router.replace({ path: "/service/infobase/list" });
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.infoBase.type = "";
        this.infoBase.content = "";
      },
      async createinfoBase() {
        this.infoBase.image = this.imgUrlList.join(",");
        this.infoBase["category.id"] = this.infoBase.type;
        this.infoBase["updateUser.id"] = this.user.id;
        const responseData = await createKnowledgeBase(this.infoBase);
        if (responseData && responseData.errorCode === 0 && responseData.data) {
          Notification.success({
            title: "成功",
            message: this.isEdit ? "修改成功" : "新增成功",
          });
        } else {
          Notification.error({
            title: "错误",
            message: responseData.errorMessage,
          });
        }
      },

      /**
       * 触发文件选择
       */
      triggerSelectFile() {
        document.querySelector("#orderImageFile").click();
      },

      /**
       * 选中
       */
      selectedImageFile($event) {
        let files = $event.target.files;
        let file = null;
        if (files && files.length) {
          file = files[0];
        }

        if (file && /^image\/\w+/.test(file.type)) {
          if (file.size / 1024 / 1024 / 10 > 1) {
            //10M
            Notification.error({
              title: "错误",
              message: "请选择一个小于10M图片",
            });
            return;
          }
        } else {
          Notification.error({
            title: "错误",
            message: "请选择一个图片文件",
          });
          return;
        }
        //获取 URL.createObjectURL 地址
        const fileImageUrl = getFileUrl("#orderImageFile");
        let image = new Image();
        image.src = fileImageUrl;
        this.loading = true;
        image.onload = () => {
          this.imageList.push({ fileImage: fileImageUrl, serverImage: "" });
          this.uploadImageFile(file);
        };
        image.onerror = () => {
          Notification.error({
            title: "错误",
            message: "请选择一个正确的图片文件",
          });
        };
      },
      /**
       * @param index 在imageList中的下标
       */
      delImage(index) {
        this.imageList.splice(index, 1);
      },

      /**
       * 上传图片
       * @param imageFile Blob file
       */
      async uploadImageFile(imageFile) {
        const uploadData = await uploadImageFile(imageFile);
        if (
          uploadData &&
          uploadData.errorCode === 0 &&
          uploadData.data &&
          uploadData.data.result
        ) {
          this.imageList[this.imageList.length - 1].serverImage =
            uploadData.data.fpicurl;
          this.loading = false;
          document.getElementById("headImgfileForm").reset();
        }
      },
    },
    async created() {
      this.getAllOrderCategory();
    },
    mounted() {
      let qlContent = document.getElementsByClassName("ql-editor")[0];
      qlContent.setAttribute("data-placeholder", "在此编辑内容");
    },
  };
</script>
